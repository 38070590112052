/* Footer */
.footer{
    background-color: $color--white; //$color--blue;

    &__copyright{
        border-top: .2rem solid $color--purple;
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
        color: $color--purple;
        font-size: 1.3rem;

        p{
            margin-bottom: 0;
        }
    }
}

.footer-top{
    padding: 2rem 0;
    background-color: $color--white;
    border-top: 1.8rem solid $color--purple;
    color: $color--purple;
}
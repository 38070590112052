.panel-table{
    border-radius: 1rem;
	background-color: $color--white;
    box-shadow: 0 .3rem .6rem rgba($color--black, .16);

    &__header{
        border-radius: 1rem 1rem 0 0;
        padding: 1.5rem 4.5rem;

        .panel-table--green &{
            background-color: $color--green;
        }

        .panel-table--yellow &{
            background-color: $color--yellow;
        }

        .panel-table--orange &{
            background-color: $color--orange;
        }

        .panel-table--red &{
            background-color: $color--red;
        }

        .panel-table--gray &{
            background-color: $color--gray-table;
        }

        &-title{
            font-size: 2.2rem;
            font-weight: 700;
            margin: 0;
            color: $color--white;
    
            .panel-table--gray &{
                color: $color--gray;
            }

            .panel-table--default &{
                color: $color--purple;
            }
        }
    }

    &__body{
        padding: 0 2rem 2.5rem;

        .table-responsive{
            margin-bottom: 2rem;
        }

        .table{
            margin-bottom: 0;
            
            td, 
            th {
                vertical-align: middle;
                border: 0;
                line-height: 1.1;
            }
            
            thead{
                tr{
                    th{
                        font-size: 1.3rem;
                        // padding: 1rem 1.5rem;
                        padding: 1rem .8rem;
                        background-color: $color--gray-thin;
                        color: $color--gray;
                        font-weight: 600;

                        .panel-table--default &{
                            background-color: $color--blue;
                            color: $color--white;
                        }
                    }
                }
            }
            
            tbody{
                tr{
                    td{
                        // padding: .4rem 1.5rem;
                        padding: .4rem .8rem;
                        font-size: 1.3rem;
                        font-weight: 400;
                        color: $color--gray;
                        
                        .icon-table-check,
                        .icon-table-folder-plus{
                            color: $color--gray-table-icon;
                        }

                        .icon-table-folder-plus{
                            cursor: pointer;
                            
                            &:hover > g > path:first-child{
                                fill: $color--gray !important;
                            }
                        }

                        
                    }

                    &:nth-child(even of .table-row){
                        td{
                            background-color: #F5F5F5;

                            .panel-table--green &{
                                background-color: rgba($color--green-regular, .16);
                            }

                            .panel-table--orange &{
                                background-color: rgba($color--orange, .16);
                            }

                            .panel-table--red &{
                                background-color: rgba($color--red, .16);
                            }

                            .panel-table--gray &{
                                background-color: $color--gray-table-even;
                            }
                        }
                    }

                    &.table-row{
                        &.is-active {
                            > td{
                                background-color: rgba($color--yellow, .18) !important;

                                .icon-table-folder-plus {
                                    > g > path:first-child{
                                        fill: $color--gray !important;
                                    }
                                    
                                    .line-vertical{
                                        display: none;
                                    }
                                }                               

                            }

                            + .table-sub-row{
                                display: table-row;
                            }
                        }
                    }

                    &.table-sub-row{
                        display: none;

                        th, td{
                            vertical-align: top;
                            line-height: 1.3;
                            padding: .5rem .75rem;
                        }
                    }

                    &.is-disabled{
                        td{
                            color: rgba($color--gray, .38);
                            
                            .btn-table{
                                color: rgba($color--gray, .38);
                                background-color: transparent;
                                pointer-events: none;
                            }
                        }
                    }
                }
            }
        }
    }

}

.btn-table{
    @include transition(.4s);
	display: inline-block;
	min-width: 10rem;
	min-height: 2.5rem;
	padding: .4rem 1rem;
	text-align: center;
	vertical-align: middle;
	border: 1px solid $color--gray-border;
	font-weight: 700;
	font-size: 1.2rem;
	line-height: 1.3;
    color: $color--white;

    .panel-table--green &{
        background-color: $color--green-regular;

        &:hover{
            background-color: darken($color--green-regular, 8%);
        }
    }

    .panel-table--orange &{
        background-color: $color--orange;

        &:hover{
            background-color: darken($color--orange, 8%);
        }
    }

    .panel-table--yellow &{
        background-color: $color--orange;

        &:hover{
            background-color: darken($color--yellow, 8%);
        }
    }

    .panel-table--red &{
        background-color: $color--red;

        &:hover{
            background-color: darken($color--red, 8%);
        }
    }
}

.btn-small-table{
    min-width: 0;
    min-height: 0;
    padding: .5rem 2rem;
}
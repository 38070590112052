.page-access{
    &__sidebar{
        @include flex-align-items(center);
        @include flex-wrap(wrap);
        min-height: calc(100vh - 4.8rem);
        text-align: center;
        padding-top: 4rem;

        @include max-screen(bp("tablet")) {
            min-height: 30rem;
        }

        &-logo{
            display: inline-block;
            margin-bottom: 2.4rem;
        
            img{
                max-width: 30rem;
            }
        }

        &-content{
            width: 100%;
        }

        &-footer{
            width: 100%;
            text-align: center;
            padding: 3rem 0;
            
            @include min-screen(bp("tablet")) {
                @include flex-justify-content(center);
                @include flex-wrap(wrap);
                align-self: flex-end;
                position: absolute;
            }
        }
    }

    &__content{
        display: block;
        margin: 17rem auto 10rem;
        width: 51rem;
        font-weight: 500;

        &.is-large{
            margin: 8rem auto;
            width: 72rem;
        }

        @include max-screen(bp("mobile")) {
            margin: 15rem 15px;
        }

        form{
            .row{
                margin-right: -8px;
                margin-left: -8px;

                .col, 
                [class*=col-] {
                    padding-right: 8px;
                    padding-left: 8px;
                }
            }
        }
    }

    &__footer{
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        text-align: center;
        padding: 2rem 0;
    }
}
.modal{
	padding-left: 0 !important;
	padding-right: 0 !important;

	&-open{
		padding-left: 0 !important;
		padding-right: 0 !important;
	}

	&-close{
		@include reset-button();
		@include transition(.4s);
		position: absolute;
		top: .5rem;
		right: 1.5rem;
		width: 1.5rem;
		height: 1.5rem;
		font-size: 2.4rem;
		color: $color--purple;
		background: {
			image: url(../images/icons/fechar.svg);
			repeat: no-repeat;
			size: contain;
		}
		z-index: 9;

		&:hover{
			opacity: .7;
		}
	}

	&-dialog{
		max-width: 34rem;

		&-centered{
			margin-left: auto;
			margin-right: auto;
		}

		&.modal-lg{
			max-width: 96rem;
		}
	}

	&-content{
		position: relative;
		border-radius: 1rem;
		border: 0;
	}

	&-body{
		padding: 3.5rem 2rem 2.5rem;
	}

	.small, small {
		font-size: 75%;
	}
}
.panel{
    @include transition(.4s);
    border-radius: 1rem;
	background-color: $color--white;
    box-shadow: 0 .3rem .6rem rgba($color--black, .16);
    color: $color--black;
    margin-bottom: 2rem;
    
    &__header{
        border-radius: 1rem 1rem 0 0;
        padding: 1.5rem;

        &-title{
            font-size: 2.2rem;
            margin: 0;
            font-weight: 700;
        }
    }
    
    &__body{
        padding: 1.8rem 3.6rem;
    }
    
    a:hover &{
        @include translate(0, -0.15rem);
        box-shadow: 0 .6rem 1rem rgba($color--black, .16);
    }

    &--metrics{
        width: 100%;
        padding: 2rem;
        background-color: $color--gray;
        color: $color--white;

        h3{
            font-size: 1.6rem;
            font-weight: 700;
            margin: 0;
        }

        .number{
            display: block;
            text-align: center;
            font-size: 5.8rem;
            font-weight: 500;
            line-height: .8;
        }
    }

    &--number{
        padding: .5rem .5rem .5rem 2rem;

        h3{
            font-size: 2.5rem;
            font-weight: 700;
            color: $color--white;
            margin: 0;
        }

        &-green{
            background-color: $color--green;

            .number-large{
                color: $color--green;
            }
        }

        &-orange{
            background-color: $color--orange;

            .number-large{
                color: $color--gray-light;
            }
        }

        &-yellow{
            background-color: $color--yellow-light;

            .number-large{
                color: $color--yellow-light;
            }
        }

        &-red{
            background-color: $color--red;

            .number-large{
                color: $color--red;
            }
        }

        &-gray{
            background-color: $color--gray;
        }

        .number-large{
            background-color: $color--white;
        }
    }

    .number-large{
        border-radius: 1rem;
        display: block;
        font-size: 7rem;
        font-weight: 600;
        line-height: 12.5rem;
        min-width: 12.5rem;
        text-align: center;

        &--2x{
            line-height: 10rem;
            padding: 0 3.5rem;

            @include min-screen(bp("mobile")) {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }
        }
    }
}
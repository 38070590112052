/* Main Menu */
.nav-header{
	> ul{
		@include flex-wrap(wrap);
		margin: 0;

		> li{

			> a{

			}
		}
	}
}

.nav-footer{
	> ul{
		@include flex-justify-content(center);
		@include flex-wrap(wrap);
		margin: 0;

		> li{
			margin: .5rem 1.5rem;

			> a{
				&:hover{
					color: $color--yellow;
				}
			}
		}
	}
}

.nav-steps{
	@include flex-justify-content(center);
	@include flex-wrap(wrap);
	margin-bottom: 3rem;
	position: absolute;
	top: 10rem;
	left: 0;
	right: 0;

	&__item{
		display: block;
		width: 10rem;
		height: .7rem;
		border-radius: 50rem;
		background-color: $color--white;
		margin: 0 1.5rem 3rem;

		&:not(.is-active){
			pointer-events: none;
			opacity: .46;
		}
	}
}

.navbar-toggler{
	padding: 0;
	border: 0;
	font-size: 2.3rem;

	&-icon{
		position: relative;
		background-image: none !important;

		> span{
			display: block;
			position: absolute;
			width: 84%;
			left: 8%;
			height: .3rem;
			border-radius: 50rem;
			background-color: $color--white;

			.theme-dark &{
				background-color: $color--white;
			}

			&:nth-child(1){
				top: .5rem;
			}

			&:nth-child(2){
				top: 50%;
				margin-top: -.15rem;
			}

			&:nth-child(3){
				bottom: .5rem;
			}
		}
	}
}
.card-budget{
    @include flex-align-items(center);
    @include flex-wrap(wrap);
    @include transition(.4s);
    border-radius: 1rem;
	background-color: $color--white;
    box-shadow: 0 .3rem .6rem rgba($color--black, .16);
    color: $color--black;
    padding: 1.5rem 1rem;
    position: relative;
    height: 100%;

    &__logo{
        text-align: center;
        
        @include min-screen(bp("mobile")) {
            -ms-flex: 0 0 15rem;
            flex: 0 0 14rem;
            max-width: 14rem;
            margin: 0;
        }

        @include max-screen(bp("mobile")) {
            width: 100%;
            margin-bottom: 2rem;
        }

        img{
            max-width: 100%;
            max-height: 7.8rem;
        }
    }
    
    &__caption{
        font-size: 1.4rem;
        padding: 0 1rem 0 2rem;
        color: $color--gray;
        
        @include min-screen(bp("mobile")) {
            -ms-flex-preferred-size: 0;
            flex-basis: 0;
            -ms-flex-positive: 1;
            flex-grow: 1;
            max-width: 100%;
            border-left: 1px solid $color--gray-border-card;
        }

        @include max-screen(bp("mobile")) {
            width: 100%;
            text-align: center
        }
    }
    
    &__trash{
        @include transition(.4s);
        display: block;
        position: absolute;
        width: 2.4rem;
        height: 2.4rem;
        bottom: 1.5rem;
        right: 1.5rem;
        cursor: pointer;
        color: $color--purple;
        font-size: 2.2rem;
        text-align: center;
        line-height: 1;
        z-index: 3;

        &:hover{
            opacity: .7;
        }
    }
}
/* Social Media */
.social-media{
	@include flex-wrap(wrap);
	@include flex-justify-content(center);

	> li{
		padding: .5rem;

		a{
			@include transition(.4s);
			display: block;
			width: 3.5rem;
			height: 3.5rem;
			line-height: 3.5rem;
			text-align: center;
			border-radius: 50rem;
			font-size: 2.4rem;
			background-color: $color--white;
			color: $color--blue;

			&:hover{
				background-color: $color--yellow;
				// color: ;
			}
		}
	}
}
table{
	thead{
		tr{
			th{

			}
		}
	}

	tbody{
		tr{
			td{
				
			}
		}
	}

	tfoot{
		tr{
			td{
				
			}
		}
	}
}

.table-proposta{
	tbody{
		tr{
			th{
				text-align: right;
				font-size: 1.6rem;
				font-weight: 700;
			}

			td{
				font-size: 1.4rem;
			}

			th, td{
				padding: .3rem .2rem;
				vertical-align: middle;
			}
		}
	}
}
.btn{
	@include transition(.4s);
	display: inline-block;
	min-width: 18rem;
	min-height: 4.5rem;
	padding: 1.4rem 3.2rem;
	text-align: center;
	vertical-align: middle;
	border: 0;
	border-radius: 50rem;
	font-weight: 600;
	font-size: 1.6rem;
	line-height: 1.3;

	span,
	svg,
	img{
		display: inline-block;
		vertical-align: middle;
	}

	svg{
		margin-left: 1.3rem;
	}

	&-rounded-small{
		border-radius: .9rem;
	}

	&-outline{
		border: .2rem solid transparent;
		background-color: transparent;
	}

	&-block{
		display: block;
		width: 100%;
	}
	
	&-auto{
		min-width: auto !important;

		&-mnh{
			min-height: auto !important;
		}
	}

	&-md{
		min-height: 4rem;
		min-width: 9rem;
		padding: .8rem 1.6rem;
		line-height: 1.4;
		font-size: 1.5rem;
	}

	&-sm{
		border-width: 1px;
		font-size: 1.4rem;
		font-weight: 500;
		min-height: 3.5rem;
		min-width: 13rem;
		padding: .75rem 1.6rem;
	}

	&-link{
		display: inline-block;
		position: relative;
		font-size: 2.4rem;
		font-weight: 500;
		text-decoration: none;
		color: $color--blue;

		&:hover,
		&:focus,
		&:active{
			text-decoration: none;
			color: darken($color--blue, 6%);
		}

		&:hover::after{
			left: 1rem;
			right: 1rem;
			background-color: darken($color--blue, 6%);
		}

		&::after{
			@include pseudo();
			@include transition(.4s);
			height: .1rem;
			left: 0;
			right: 0;
			bottom: 0;
			background-color: $color--blue;
		}
	}

	&-rounded-md{
		border-radius: .6rem;
	}

	&:disabled{
		opacity: .5;
		cursor: not-allowed;
		pointer-events: none;
	}
}

@each $name, $color in $colors {
	.btn-#{$name} {
		&:not(.btn-outline){
			background-color: $color;
			color: $color--white;


			&.is-active,
			&:hover{
				background-color: darken($color, 6%);
			}
		}

		&.btn-outline{
			border-color: $color;
			color: $color;

			&.is-active,
			&:hover{
				background-color: $color;
				color: $color--white;

				@if $name == 'white'{
					color: $color--purple;
				}@else{
					color: $color--white;
				}
			}
		}
	}

	.btn-link-#{$name} {
		color: $color;

		&::after{
			background-color: $color;
		}

		&:hover,
		&:focus,
		&:active{
			color: darken($color, 6%);

			&::after{
				background-color: darken($color, 6%);
			}
		}
	}

	.btn-panel-#{$name} {
		background-color: $color;

		&:hover,
		&:focus,
		&:active{
			background-color: darken($color, 6%);
		}
	}

	.btn-bg-#{$name} {
		background-color: $color;
	}
}

.btn-panel{
	@include transition(.4s);
	display: inline-block;
	padding: .8rem 1.5rem;
	text-align: center;
	vertical-align: middle;
	border: 0;
	border-radius: .8rem;
	font-weight: 700;
	font-size: 1.4rem;
	line-height: 1.3;
}


.cta-fixed-mobile {
	position: fixed;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	padding: 18px 10px;
	font-size: 18px;
	border-radius: 0;
	border: 0;
	z-index: 9999;
}

.arrow-pagination{
	@include transition(.4s);
	font-size: 4rem;

	a:hover &{
		color: $color--purple;
	}
}
h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  // margin-bottom: ;
  // font-family: ;
  font-weight: 500;
  line-height: 1.3;
  margin-bottom: 1.8rem;
}

h1, .h1 { 
  font-size: 3.3rem; 
}
h2, .h2 { 
  font-size: 2.8rem; 
}
h3, .h3 { 
  font-size: 2.4rem; 
}
h4, .h4 { 
  font-size: 2.1rem; 
}
h5, .h5 { 
  font-size: 1.8rem; 
}
h6, .h6 { 
  font-size: 1.6rem; 
}

.text-small{
  font-size: 1.2rem;
  line-height: 1.1;
}

.text-underline{
  text-decoration: underline;
  
  &:hover{
    text-decoration: underline;
  }
}

.text-content{
  h1{
    margin-bottom: 3rem;
  }

  ul, ol, p{
    margin-bottom: 1.8rem;
  }

  ul, ol{
    margin-left: 1.8rem;

    li{
      margin-bottom: 1rem;
    }
  }

  ul{
    list-style-type: disc;
  }

  ol{
    list-style-type: decimal;
  }
}
.pagination{
    .page-item{
        padding: .25rem;
        
        .page-link{
            min-width: 2.5rem;
            background-color: $color--gray-pagination;
            margin: 0;
            border: 0;
            border-radius: .6rem;
            font-weight: 700;
            text-align: center;
            color: $color--blue;
            
            &:hover,
            &:focus,
            &:active{
                outline: 0;
                box-shadow: none;
            }

            &.is-points{
                background-color: transparent;
                font-size: 2rem;
                line-height: .5;
            }

            .panel-table--green &{
                color: $color--green-regular;
            }

            .panel-table--orange &{
                color: $color--orange;
            }

            .panel-table--red &{
                color: $color--red;
            }

            .panel-table--gray &{
                color: $color--gray;
            }

            .panel-table--yellow &{
                color: $color--yellow;
            }
            
            // .panel-table--default &{
            //     color: $color--blue;
            // }
        }

        &.active .page-link{
            color: $color--white;
            background-color: $color--blue;

            .panel-table--green &{
                background-color: $color--green-regular;
            }

            .panel-table--orange &{
                background-color: $color--orange;
            }

            .panel-table--red &{
                background-color: $color--red;
            }

            .panel-table--gray &{
                background-color: $color--gray;
            }

            .panel-table--yellow &{
                background-color: $color--yellow;
            }
        }
    }
}
// Home
.sec-webdoor{
    &__content{
        width: 29.5rem;
        margin: 5rem auto;
        text-align: center;
        color: $color--blue;       

        &-logo{
            display: block;
            border-bottom: .4rem solid $color--purple;
            padding-bottom: 3rem;
            margin-bottom: 3rem;
        }
    }
}

.sec-about{
    &__image{
        position: relative;
        margin: 0;
        height: 100%;
        padding-bottom: 100%;

        img{
            @include image-cover();
        }
    }

    &__content{
        padding: 4.5rem;

        @include max-screen(bp("tablet")) {
            padding: 5rem 0;
        }

        h2{
            margin-bottom: 3rem;
        }
    }
}

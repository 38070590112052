/* Header */
.header{
	&__logo{
		margin: 0;
		line-height: 1;
	}

	&__top{
		position: relative;
		padding: 2rem 0 1rem;
		background-color: $color--white;
		box-shadow: 0 .3rem .6rem rgba($color--black, .16);
		z-index: 2;
	}

	&__main{
		padding-top: 2rem;
		color: $color--black;

		&-nav{
			> ul{
				margin: 0;
				@include flex-wrap(wrap);

				@include max-screen(bp("tablet")) {
					padding-bottom: 2rem;
				}

				> li{
					position: relative;
					margin-right: 1rem;

					@include max-screen(bp("tablet")) {
						width: 100%;
						text-align: center;
					}
					
					a{
						display: block;
						border-radius: 1rem 1rem 0 0;
						padding: 2rem 3rem;
						font-weight: 600;
						
						&.item-search{
							padding: 1.5rem;
							margin-left: 1rem;
						}

						@include max-screen(bp("tablet")) {
							border-radius: 1rem;
							padding: 2rem 3rem;
						}

						&.is-active,
						&:hover{
							background-color: $color--white;

							.bg-orange &{
								color: $color--orange;
							}

							.bg-purple &{
								color: $color--purple;
							}
						}
					}
				}
			}
		}
	}
}

.header-home{
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	padding-top: 3.5rem;
	padding-bottom: 1rem;
	z-index: 9;
}
.alert-text{
    padding: 2.5rem;
    border: .2rem solid $color--gray-alert;
    border-radius: 1rem;
    color: $color--black;

    a{
        color: $color--blue-link;
        text-decoration: underline;
    }
}
// Typo
$font--serif: sans-serif;
$font--quicksand: 'Quicksand', sans-serif;
$font--graphik: 'Graphik', sans-serif;
// $font--default: $font--quicksand;
$font--default: $font--graphik;

// Font size
$font--default-size: 62.5%;
$font--tablet-size: 58%;
$font--mobile-size: 58%;

// Text Format
$line-height--default: 1.4;
$font-weight--default: 400;

// Colors
$color--white: #ffffff;
$color--black: #000000;
$color--gray: #707070;
$color--gray-readonly: #6F6F6F;
$color--gray-light: #909090;
$color--gray-thin: #EFEFEF;
$color--gray-border: #D6D6D6;
$color--gray-pagination: #E2E2E2;
$color--gray-border-card: #E0E0E0;
$color--purple: #5A2B78;
$color--yellow: #FDB30B;
$color--yellow-light: #fad02c;
$color--yellow-neon: #FFFF00;
$color--blue: #073367;
$color--light: #F3F5F8;
$color--blue-link: #0045FF;
$color--green-regular: #137B06;
$color--green: #3bab59; //#0E4307;
// $color--orange: #FF7F00;
$color--orange: #fd780b;
$color--red: #c4381d; //#790D09;
$color--gray-table: #D0D0D0;
$color--gray-table-even: #E3E3E3;
$color--gray-table-icon: #A5A5A5;
$color--gray-alert: #ACACAC;
$color--thin: #f8f1ef;

$color--success: #007116;
$color--error: #FA0000;

$color--text: $color--gray;
$color--background-body: $color--white;
$color--default-link: $color--black;
$color--default-link-hover: $color--black;

$border-radius--default: 1rem;

$colors: (
  'white': $color--white,
  'black': $color--black,
  'gray': $color--gray,
  'gray-light': $color--gray-light,
  'gray-thin': $color--gray-thin,
  'success': $color--success,
  'error': $color--error,
  'purple': $color--purple,
  'yellow': $color--yellow,
  'yellow-light': $color--yellow-light,
  'yellow-neon': $color--yellow-neon,
  'blue': $color--blue,
  'green': $color--green,
  'green-regular': $color--green-regular,
  'thin': $color--thin,
  'orange': $color--orange,
  'red': $color--red,
);

$font-weight: (
  'thin': 100,
  'light': 300,
  'normal': 400,
  'medium': 500,
  'semibold': 600,
  'bold': 700,
  'ultra': 800,
  'black': 900,
);

// Structure
$breakpoints: (
  'minimum': 320px, //*The smallest width that the site is able to shrink to */
  'tiny': 350px,
  'small': 480px,
  'mobile': 576px,
  'phablet': 768px, //*essentially the maximum for iPads in portrait*/
  'tablet': 992px, //*!MAJOR BREAK POINT!*/ /*good place to switch to tablet view*/
  'large': 1024px, //*maximum for iPads in landscape*/
  'page': 1200px, //*!MAJOR BREAK POINT!*//*Point at which the edge of the desktop design meets the edge of the screen*/
  'wide': 1400px,
  'big': 1600px,
  'menu': 1280px
);
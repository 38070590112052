.card-accordion{
    background-color: $color--white;
    border-radius: 1rem;
    margin-bottom: 2rem;
    box-shadow: 0 .3rem .6rem rgba($color--black, .16);

    &__title{
        position: relative;
        font-size: 1.6rem;
        font-weight: 700;
        padding: 2.5rem 6rem 2.5rem 3rem;
        cursor: pointer;
        color: $color--black;

        .accordion--purple &{
            color: $color--purple;
        }

        &::after{
            content: '';
            display: block;
            position: absolute;
            width: 3.2rem;
            height: 3.2rem;
            top: 1.8rem;
            right: 2rem;
            background: {
                image: url(../images/arrow-accordion.svg);
                repeat: no-repeat;
                position: center;
                size: contain;
            }

            .accordion--purple &{
                background-image: url(../images/arrow-accordion-purple.svg);
            }
            
            .collapsed &{
                
            }
        }
    }

    &__caption{
        @extend .text-content;
        padding: 0 3rem 3rem;
        
        .accordion--purple &{
            color: $color--purple;
        }
    }
}
.card-plan{
    @include transition(.4s);
    position: relative;
    border-radius: 1rem;
    overflow: hidden;
    box-shadow: 0 .3rem .6rem rgba($color--black, .3);
    background-color: $color--white;

    a:hover &{
        @include min-screen(bp("tablet")) {
            box-shadow: 0 .3rem .6rem rgba($color--black, .5);
            @include scale(1.04);
        }
    }

    &--recommended{
        @include min-screen(bp("tablet")) {
            @include scale(1.1);
            
            a:hover &{
                @include scale(1.14);
            }
        }
    }

    &__header{
        @include flex-align-items(center);
        @include flex-justify-content(center);
        position: relative;
        height: 7.2rem;
        font-size: 2.1rem;
        font-weight: 500;
        padding: 1rem;
        background-color: $color--purple;
        color: $color--white;

        .card-plan--green &{
            background-color: $color--green;
        }

        .card-plan--orange &{
            background-color: $color--orange;
        }

        .card-plan--red &{
            background-color: $color--red;
        }

        .card-plan--yellow &{
            background-color: $color--yellow-light;
        }

        &::after{
            @include pseudo();
            width: 0;
            height: 0;
            bottom: -1.9rem;
            left: 50%;
            margin-left: -1.6rem;
            border: {
                bottom: 0;
                top: 2rem solid $color--purple;
                right: 1.6rem solid transparent;
                left: 1.6rem solid transparent;
            }

            .card-plan--green &{
                border-top-color: $color--green;
            }
    
            .card-plan--orange &{
                border-top-color: $color--orange;
            }
    
            .card-plan--red &{
                border-top-color: $color--red;
            }

            .card-plan--yellow &{
                border-top-color: $color--yellow-light;
            }
        }
    }

    &__body{
        padding: 3.5rem 2rem 1rem;
        text-align: center;
        color: $color--black;

        .card-plan--recommended &{
            border-right: 1px solid transparent;
            border-left: 1px solid transparent;
        }

        .card-plan--green &{
            border-color: $color--green;
        }

        .card-plan--orange &{
            border-color: $color--orange;
        }

        .card-plan--red &{
            border-color: $color--red;
        }

        .card-plan--yellow &{
            border-color: $color--yellow-light;
        }
    }
    
    &__footer{
        padding: 1rem 2rem 3.5rem;
        text-align: center;
        border-radius: 0 0 1rem 1rem;

        .card-plan--recommended &{
            border-right: 1px solid transparent;
            border-left: 1px solid transparent;
            border-bottom: 1px solid transparent;
        }

        .card-plan--green &{
            border-color: $color--green;
        }

        .card-plan--orange &{
            border-color: $color--orange;
        }

        .card-plan--red &{
            border-color: $color--red;
        }

        .card-plan--yellow &{
            border-color: $color--yellow-light;
        }
    }

    &__title{
        font-size: 2.4rem;
        font-weight: 700;
        margin-bottom: .8rem;
    }

    &__list{
        color: $color--gray-light;
        
        li{
            padding: .5rem;

            &:not(:last-child){
                border-bottom: 1px solid rgba($color--gray-light, .3);
            }
        }
    }

    &__cta{
        background-color: $color--purple;
        color: $color--white;
        font-weight: 700;

        .card-plan--green &{
            background-color: $color--green;
        }

        .card-plan--orange &{
            background-color: $color--orange;
        }

        .card-plan--red &{
            background-color: $color--red;
        }

        .card-plan--yellow &{
            background-color: $color--yellow-light;
        }

        &:hover{
            color: $color--white;
        }
    }
}
.air-datepicker{
    font-family: $font--default;
    border-radius: 1rem;
    overflow: hidden;
    border: 0 !important;
    box-shadow: 0 .3rem .6rem rgba($color--black, .3) !important;
    color: $color--gray;

    // @include max-screen(bp("mobile")) {
        width: 30rem;
    // }

    &-nav{
        padding: .4rem 2rem;
        align-items: center;
        background-color: $color--gray;
        color: $color--white;

        &--title{
            // font-size: 4.8rem;
            font-weight: 700;

            // @include max-screen(bp("mobile")) {
                font-size: 3rem;
            // }

            &:hover{
                background-color: transparent;
            }
        }

        &--action{
            position: relative;
            width: 3rem;
            height: 3rem;
            border-radius: 50rem;
            background-size: contain !important;
            background-color: transparent !important;
            background-repeat: no-repeat !important;
            background-position: center !important;

            &:active,
            &:hover,
            &:focus{
                background-color: transparent;
            }

            &[data-action="prev"]{
                background-image: url(../images/arrow-calendar-prev.svg);
            }

            &[data-action="next"]{
                background-image: url(../images/arrow-calendar-next.svg);
            }

            svg{
                display: none;
            }
        }
    }

    &-cell{
        margin: 0 auto;
        // font-size: 3.2rem;
        font-weight: 700;
        // width: 8rem;
        border-radius: 50rem;

        // @include max-screen(bp("mobile")) {
            width: 7rem;
            font-size: 2.4rem;
        // }

        &.-current-.-selected-,
        &.-current-{
            color: inherit;
        }

        &.-selected-,
        &.-focus-.-selected-{        
            color: $color--white;
            background-color: $color--yellow;

            &:hover{
                color: $color--white;
            }
        }

        &.-disabled-{
            pointer-events: none;
        }
    }

    &-body--cells{
        &.-years-,
        &.-months-{
            // grid-auto-rows: 8rem;
            
            // @include max-screen(bp("mobile")) {
                grid-auto-rows: 7rem;
            // }
        }
    }
}
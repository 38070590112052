.select-profile{
    @include flex-align-items(center);
    @include flex-wrap(wrap);
    @include transition(.4s);
    height: calc(50vh - 2.4rem);
    padding: 0 20%;
    
    @include max-screen(bp("wide")) {
        padding: 0 15%;
    }

    @include max-screen(bp("phablet")) {
        height: 45vh;
        padding: 0 4rem;
    }

    &__text{
        width: calc(100% - 5.5rem);
        font-size: 2.8rem;
        font-weight: 500;
    }

    &__circle{
        display: block;
        position: relative;
        width: 3rem;
        height: 3rem;
        border-radius: 50rem;
        margin-right: 2.5rem;
        background-color: $color--white;

        &::before{
            @include transition(.2s);
            @include pseudo();
            top: 50%;
            right: 50%;
            bottom: 50%;
            left: 50%;
            background-color: $color--purple;
            border-radius: 50rem;

            a:hover &{
                top: 20%;
                right: 20%;
                bottom: 20%;
                left: 20%;
            }
        }
    }

    &--buyer{
        background-color: $color--purple;
        color: $color--white;

        a:hover &{
            background-color: darken($color--purple, 6%);
        }
    }
    
    &--seller{
        background-color: $color--gray-thin;
        color: $color--purple;

        a:hover &{
            background-color: darken($color--gray-thin, 6%);
        }
    }
}
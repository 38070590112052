.list-order{
    &__item{
        position: relative;
        padding-left: 6rem;
        padding-top: 1.2rem;
        font-size: 1.8rem;
        min-height: 5rem;
        margin-bottom: 2.5rem;

        &-number{
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 5rem;
            height: 5rem;
            line-height: 4.6rem;
            font-size: 3rem;
            font-weight: 700;
            border: .2rem solid $color--white;
            border-radius: 50rem;
            text-align: center;
        }
    }
}
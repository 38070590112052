.webdoor{
    position: relative;

    &__image{
        position: relative;
        padding-bottom: 100vh;
        margin-bottom: 0;

        @include max-screen(bp("tablet")) {
            padding-bottom: 80vh;    
        }

        &::before{
            @include pseudo();
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: rgba($color--black, .75);
            z-index: 2;
        }

        img{
            @include image-cover();
        }
    }

    &__caption{
        @include translate(0, -50%);
        position: absolute;
        top: 50%;
        width: 100%;
        z-index: 4;
        color: $color--white;
        padding: 15%;
        
        @include max-screen(bp("tablet")) {
            padding: 5%;            
        }
    }

    &__title{
        font-weight: 400;
        font-size: 4.8rem;
        margin-bottom: 4rem;

        strong{
            font-weight: bolder;
        }
    }
}
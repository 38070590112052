/* Helpers */
.hover-zoom-image{
	overflow: hidden;

	img{
		@include transition(.3s);
	}

	&:hover{
		img{
			@include scale(1.03);
		}
	}
}

.border-radius{
	border-radius: $border-radius--default;
}

// Border Color
@each $name, $color in $colors {
	.border-#{$name} {
		border-color: $color !important;
	}
}

// Text Color
@each $name, $color in $colors {
	.text-#{$name} {
		color: $color !important;
	}
}

// Background Color
@each $name, $color in $colors {
	.bg-#{$name} {
		background-color: $color !important;
	}
}

// Font Weight
@each $name, $weight in $font-weight {
	.font-weight-#{$name} {
		font-weight: $weight !important;
	}	
}

.grecaptcha-badge{
	opacity: 0;
	visibility: hidden;	
}

.underline{
	text-decoration: underline;
}

.box-shadow{
	box-shadow: 0 .3rem .6rem rgba($color--black, .16);
}
/* Forms / Inputs */

.form-group{
	margin-bottom: 2.5rem;
}

.form-label{
	font-size: 1.5rem;
}

.form-control{
	height: 5.5rem;
	border: .2rem solid transparent;
	border-radius: .8rem;
	box-shadow: 0 .3rem .6rem rgba($color--black, 0.16);
	color: $color--purple;
	font-size: 1.6rem;
	font-weight: 500;
	padding-left: 1.5rem;
	padding-right: 1.5rem;
	outline: 0;

	.modal-proposta &{
		color: $color--gray;
		font-weight: 300;
	}

	&:disabled, 
	&[readonly] {
		color: $color--gray-readonly;
		border-color: $color--gray-thin;
		background-color: $color--gray-thin;
		opacity: 1;
	}

	&.error{
		border-color: $color--error;
		padding-right: 4rem;
		background-image: url(../images/alert-error.svg);
		background-repeat: no-repeat;
		background-position: calc(100% - 10px) 50%;
	}
	
	&:focus{
		outline: 0;
		box-shadow: 0 .3rem .8rem rgba($color--black, 0.26);
	}

  	@include input-placeholder {
    	color: currentColor;
    	opacity: 1;
    	line-height: normal !important;
  	}
}

.input-toggle-password{
	position: relative;

	&__icon{
		display: block;
		position: absolute;
		top: 0;
		right: 0;
		width: 5.5rem;
		height: 5.5rem;
		cursor: pointer;
		z-index: 2;

		background: {
			image: url(../images/olho-abrir.svg);
			repeat: no-repeat;
			position: center;
		}

		&.is-open{
			background-image: url(../images/olho-fechar.svg);
		}
	}
}

textarea{
	resize: none;
}

.input-range-options{
	font-size: 1.3rem;
	font-weight: 500;	
}

@mixin range-thumb($color--thumb: $color--yellow){
	-webkit-appearance: none;
	height: 2.7rem;
	width: 2.7rem;
	border-radius: 50%;
	background: $color--thumb;
	cursor: ew-resize;
	// box-shadow: 0 0 2px 0 #555;
	box-shadow: none;
	transition: background .3s ease-in-out;
	&:hover {
		background: $color--thumb;
	}
}

@mixin range-track(){
	-webkit-appearance: none;
	box-shadow: none;
	border: none;
	background: transparent;
}

input[type="range"] {
	-webkit-appearance: none;
	width: 100%;
	height: 1rem;
	background-color: $color--white;
	background-image: linear-gradient($color--yellow, $color--yellow);
	background-size: 0% 100%;
	background-repeat: no-repeat;
	margin-bottom: 2rem;
	border-radius: 1rem;

	&.range-purple{
		background-color: rgba($color--purple, .32);
		background-image: linear-gradient($color--purple, $color--purple);
		
		&::-webkit-slider-thumb {
			@include range-thumb($color--purple);
		}
		  
		&::-moz-range-thumb {
			@include range-thumb($color--purple);
		}
		  
		&::-ms-thumb {
			@include range-thumb($color--purple);
		}
	
	// 	&::-webkit-slider-runnable-track  {
	// 		@include range-track();
	// 	}
		  
	// 	&::-moz-range-track {
	// 		@include range-track();
	// 	}
		  
	// 	&::-ms-track {
	// 		@include range-track();
	// 	}
	}

	[dir="rtl"] & {
		background: $color--yellow;
		background-size: 100% 100%;
		background-repeat: no-repeat;
		background-image: linear-gradient($color--white, $color--white);
		
		// &.range-purple{
		// 	background-image: linear-gradient(rgba($color--purple, .32), rgba($color--purple, .32));
		// }
	}

	&::-webkit-slider-thumb {
		@include range-thumb();
	}
	  
	&::-moz-range-thumb {
		@include range-thumb();
	}
	  
	&::-ms-thumb {
		@include range-thumb();
	}

	&::-webkit-slider-runnable-track  {
		@include range-track();
	}
	  
	&::-moz-range-track {
		@include range-track();
	}
	  
	&::-ms-track {
		@include range-track();
	}
}


.form-upload-photo{
	display: block;
	position: relative;
	width: 15rem;
	height: 15rem;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 1rem;
	cursor: pointer;

	&__image{
		position: relative;
		width: 100%;
		height: 100%;
		border-radius: 5px;
		overflow: hidden;
		box-shadow: 0 3px 6px rgba(0,0,0,.16);
		background-image: url(../images/perfil-blank.svg);
		@include background-cover();
		@include transition(.3s);

		&:hover{
			box-shadow: 0 6px 10px rgba(0,0,0,.16);
		}

		img{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			border: 0;
			@include object-fit(cover);

			&[src=""]{
				display: none;
			}
		}
	}

	&__input{
		position: absolute;
		font-size: 0;
		visibility: hidden;
		opacity: 0;
		top: 0;
		left: -99999px;
	}

	&__icon{
		display: block;
		position: absolute;
		bottom: -0.5rem;
		right: -0.5rem;
		width: 3.8rem;
		height: 3.8rem;
		border-radius: 100%;
		box-shadow: 0 3px 6px rgba(0,0,0,.16);
		background: {
			size: cover;
			position: center;
		}

		@include transition(.3s);

		.form-upload-photo:hover &{
			box-shadow: 0 6px 10px rgba(0,0,0,.16);
			@include scale(1.015);
		}

		&.icon{
			&--add{
				background-image: url(../images/perfil-adicionar.svg);
			}

			&--edit{
				background-image: url(../images/perfil-editar.svg);
			}
		}
	}

	&__error{
		color: $color--error;
		font-size: 12px;
	}
}